/* eslint-disable import/no-extraneous-dependencies */
import LoadingBar from "react-top-loading-bar"
import { useEffect, Suspense, useRef, useState } from "react"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { globalState, queryClient } from "config"
import { useRequest } from "hooks"
import { get } from "lodash"
import Favicon from "react-favicon"

import { Spinner } from "components"
import { Emitter, api } from "services"
import storage from "services/Storage"

import AdminRoutes from "./Admin"
import GuestRoutes from "./Guest"
import AdvertiserRoutes from "./Advertiser"
import OperatorRoutes from "./Operator"
import MainOperatorRoutes from "./MainOperator"

function Routes() {
  const loadingBar = useRef()
  const [isFetching, setIsFetching] = useState(false)

  const state = useQuery({
    queryKey: ["globalState"],
    queryFn: () => globalState,
    initialData: () => globalState,
    staleTime: Infinity,
  })

  let profile
  try {
    profile = JSON.parse(sessionStorage.getItem("user"))
  } catch (e) {
    //
  }
  const { pathname } = window.location
  const isAdv = pathname.includes("admin")
  const isAdminRoute =
    pathname.includes("dashboard") &&
    !pathname.includes("restricted") &&
    !pathname.includes("signin")

  const getMe = useRequest({
    queryKey: ["profile"],
    url: "profile",
    route: "",
    urlSearchParams: {
      id: get(profile, "id"),
    },
    enabled: !storage.get("user") && state.data.isAuth,
    retry: 0,
    onSuccess: (data) => {
      const user = {
        ...(get(data, "data") || {}),
        stats: get(data, "stats"),
      }

      queryClient.setQueryData(["globalState"], (old) => ({
        ...old,
        user,
        isAuth: true,
      }))

      setIsFetching(false)
      storage.set("user", user)
    },
    onError: () => {
      queryClient.setQueryData(["globalState"], (old) => ({
        ...old,
        isAuth: false,
      }))
      storage.remove("user")
      setIsFetching(false)
    },
  })

  const amIAdmin = useRequest({
    queryKey: ["getme"],
    url: `stores/${process.env.REACT_APP_STORE_ID}/members/getMe`,
    route: "",
    ttl: 0,
    onSuccess: (res) => {
      setIsFetching(false)

      const isGuest = get(res, "data.role") === "guest"
      if (get(res, "data"))
        queryClient.setQueryData(["globalState"], (old) => ({
          ...old,
          isAdminAuth: !isGuest,
        }))
    },

    enabled:
      get(state.data, "isAuth") &&
      !pathname.includes("restricted") &&
      (isAdv || pathname.includes("dashboard")),
  })

  useRequest({
    queryKey: ["store"],
    url: `stores/${process.env.REACT_APP_STORE_ID}`,
    route: "",
    ttl: 120000,
  })

  useEffect(() => {
    document.title = window.location.host

    // Top loading bar
    api.interceptors.request.use((request) => {
      if (loadingBar.current) loadingBar.current.continuousStart()
      return request
    })

    api.interceptors.response.use((res) => {
      if (loadingBar.current) loadingBar.current.complete()
      return res
    })

    Emitter.on("REFETCH_PROFILE", () => {
      getMe.refetch()
    })
  }, [])

  const role = get(amIAdmin.data, "data.role")

  const routes = [
    ...(role === "owner" ? AdminRoutes : []),
    ...(role === "operator" ? OperatorRoutes : []),
    ...(role === "main-operator" ? MainOperatorRoutes : []),
    ...(state.data.isAuth ? AdvertiserRoutes : []),
    ...GuestRoutes({ ...state.data, isFetching: amIAdmin.isFetching }),
  ]

  return (
    <div className="app">
      <Favicon url={process.env.REACT_APP_FAVICON || "/favicon.png"} />
      {isFetching && (isAdminRoute || isAdv) ? (
        <Spinner />
      ) : (
        <Suspense fallback={<Spinner />}>
          <RouterProvider router={createBrowserRouter(routes)} />
        </Suspense>
      )}
      <LoadingBar color="#6a6cf7" ref={loadingBar} shadow />
    </div>
  )
}

export default Routes
