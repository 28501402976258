import { ErrorBoundary } from "components"
import Layout from "Admin/Layout"
import { Navigate } from "react-router-dom"

import Pages from "./Pages"

const MainOperatorRoutes = [
  {
    path: "dashboard",
    element: <Layout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "order/:status",
        element: <Pages.OrderList />,
      },
      {
        path: "order/create",
        element: <Pages.OrderCreate />,
      },
      {
        path: "order/update/:id",
        element: <Pages.OrderUpdate />,
      },
      {
        path: "profile",
        element: <Pages.Profile />,
      },
      {
        path: "profile/penalty/:id",
        element: <Pages.ProfilePenaltyReply />,
      },

      {
        path: "user",
        children: [
          {
            index: true,
            element: <Pages.OperatorList />,
          },
          {
            path: "create",
            element: <Pages.OperatorCreate />,
          },
        ],
      },

      {
        index: true,
        path: "*",
        element: <Navigate to="/dashboard/order/new" />,
      },
    ],
  },
]

export default MainOperatorRoutes
